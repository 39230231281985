import React from "react";
import Head from "../../components/Head.js";
import Layout from "../../components/Layout.js";
import RightSidebar from "../../components/RightSidebar.js";

import RecommendTop from "../../components/RecommendTop.js";
import RecommendOther from "../../components/RecommendOther.js";

const allRecommendations = require("../recommendations.json");

// mark
const recommendPlaceInfo = allRecommendations[7];

const TestPage = () => {
  return (
    <>
      <Head
        title={`Austin City Tacos Recommends: ` + recommendPlaceInfo.title}
        description={`One of our top recommendations is ${recommendPlaceInfo.title}`}
      />
      <Layout>
        <main>
          <div className="main-wrapper main-wrapper-one-sidebar">
            <div className="primary-wrapper">
              <h1>Austin City Tacos Recommends: {recommendPlaceInfo.title}</h1>

              <RecommendTop data={recommendPlaceInfo}></RecommendTop>

              <div className="big-description">
                <p>
                  If you love a geniune, no fluff, really authentic
                  experience... then Granny's Tacos is your destination. They
                  have two locations at Cesar Chavez and the on other right down
                  the road on 7th. Instead of all the bells and whistles,
                  Granny's has devoted their efforts into making top-level
                  tacos. They have delicious options for both veggie and
                  breakfast tacos, and everything in between.
                </p>
                <p>
                  For our top picks, it's a tough choice. They have a machaca
                  taco with steak, eggs, and avocado that will have you speaking
                  in tongues. Then there's the chilaquil taco with we ordered
                  with mole sauce, so picking a clear-cut favorite is fruitless
                  endevour. It really is impossible to pick a bad choice at
                  Granny's, we dare you to try.
                </p>
              </div>
            </div>

            <div className="right-sidebar">
              <RecommendOther title={recommendPlaceInfo.title}></RecommendOther>
              <RightSidebar></RightSidebar>
            </div>
          </div>
        </main>
      </Layout>
    </>
  );
};

export default TestPage;
